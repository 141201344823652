<template>
  <ViewWrapper :backdropImage="getCDNImageUrl('/TFTSet3_Wallpaper.jpeg')" hideAd>
    <div class="home">
      <TeamfightLogo :size="6"/>

      <div class="search">
        <SummonerSearch/>
      </div>
    </div>
  </ViewWrapper>
</template>

<script lang="ts" setup>
import TeamfightLogo from "~/components/TeamfightLogo.vue";
import { getCDNImageUrl } from "~/utils/cdn";

definePageMeta({
  name: getPageNames().ROUTE_HOME_NAME
});

useSeoMeta({
  title: "Home"
});
</script>

<style lang="scss" scoped>
.home {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 9rem;

  .search {
    width: 50rem;
  }
}
</style>
